import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);


const router =  new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/index'
        },
        // {
        //     path: '/',
        //     redirect: '/myuserinfo'
        // },
        // {
        //     path: '/index',
        //     name: 'index',
        //     component: () => import('../page/Index.vue'),
        //     meta: { title: '首页文件', keepAlive: false }
        // },

        
        // {
        //     path: '/indexl',
        //     name: 'indexl',
        //     component: () => import('../page/Indexl.vue'),
        //     meta: { title: 'ALL FIELDS', keepAlive: false }
        // },
        {
            path: '/pcenter',
            name: 'pcenter',
            component: () => import('../page/Pcenter.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: false }
        },
        {
            path: '/pcenter/:lag',
            name: 'pcenter',
            component: () => import('../page/Pcenter.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: false }
        },

        
        {
            path: '/pcenterm',
            name: 'pcenterm',
            component: () => import('../page/PcenterM.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: false }
        },
        {
            path: '/pcenterm/:lag',
            name: 'pcenterm',
            component: () => import('../page/PcenterM.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: false }
        },


        
        // {
        //     path: '/pcenterf',
        //     name: 'pcenterf',
        //     component: () => import('../page/Pcenterf.vue'),
        //     meta: { title: 'ALL FIELDS', keepAlive: false }
        // },
        // {
        //     path: '/pcenterf/:lag',
        //     name: 'pcenterf',
        //     component: () => import('../page/Pcenterf.vue'),
        //     meta: { title: 'ALL FIELDS', keepAlive: false }
        // },

        
        // {
        //     path: '/pcentermf',
        //     name: 'pcentermf',
        //     component: () => import('../page/PcenterMf.vue'),
        //     meta: { title: 'ALL FIELDS', keepAlive: false }
        // },
        // {
        //     path: '/pcentermf/:lag',
        //     name: 'pcentermf',
        //     component: () => import('../page/PcenterMf.vue'),
        //     meta: { title: 'ALL FIELDS', keepAlive: false }
        // },


        {
            path: '/indexm',
            name: 'indexm',
            component: () => import('../page/Indexm.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: true }
        },
        {
            path: '/indexm/:lag',
            name: 'indexm',
            component: () => import('../page/Indexm.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: true }
        },

        
        
        {
            path: '/sindex/:id',
            name: 'sindex',
            component: () => import('../page/Sindex.vue'),
            meta: { title: '首页', keepAlive: false }
        },
        {
            path: '/sindex/:id/:lag',
            name: 'sindex',
            component: () => import('../page/Sindex.vue'),
            meta: { title: '首页', keepAlive: false }
        },

        
        {
            path: '/fengxiangm/:id',
            name: 'fengxiangm',
            component: () => import('../page/MindexFengxiangM.vue'),
            meta: { title: 'ALL FIELDS 趣野风向', keepAlive: false }
        },
        {
            path: '/fengxiangm/:id/:lag',
            name: 'fengxiangm',
            component: () => import('../page/MindexFengxiangM.vue'),
            meta: { title: 'ALL FIELDS 趣野风向', keepAlive: false }
        },
        {
            path: '/fengxiang/:id',
            name: 'fengxiang',
            component: () => import('../page/MindexFengxiang.vue'),
            meta: { title: 'ALL FIELDS 趣野风向', keepAlive: false }
        },
        {
            path: '/fengxiang/:id/:lag',
            name: 'fengxiang',
            component: () => import('../page/MindexFengxiang.vue'),
            meta: { title: 'ALL FIELDS 趣野风向', keepAlive: false }
        },

        
        {
            path: '/haowum/:id',
            name: 'haowum',
            component: () => import('../page/MindexHaowuM.vue'),
            meta: { title: 'ALL FIELDS 趣野好物', keepAlive: false }
        },
        {
            path: '/haowum/:id/:lag',
            name: 'haowum',
            component: () => import('../page/MindexHaowuM.vue'),
            meta: { title: 'ALL FIELDS 趣野好物', keepAlive: false }
        },

        
        {
            path: '/haowulist/:id',
            name: 'haowulist',
            component: () => import('../page/MindexHaowulist.vue'),
            meta: { title: 'ALL FIELDS 好物推荐列表', keepAlive: false }
        },
        {
            path: '/haowulist/:id/:lag',
            name: 'haowulist',
            component: () => import('../page/MindexHaowulist.vue'),
            meta: { title: 'ALL FIELDS 好物推荐列表', keepAlive: false }
        },
        
        {
            path: '/haowulistm/:id',
            name: 'haowulistm',
            component: () => import('../page/MindexHaowulistM.vue'),
            meta: { title: 'ALL FIELDS 好物推荐列表', keepAlive: false }
        },
        {
            path: '/haowulistm/:id/:lag',
            name: 'haowulistm',
            component: () => import('../page/MindexHaowulistM.vue'),
            meta: { title: 'ALL FIELDS 好物推荐列表', keepAlive: false }
        },




        {
            path: '/haowu/:id',
            name: 'haowu',
            component: () => import('../page/MindexHaowu.vue'),
            meta: { title: 'ALL FIELDS 趣野好物', keepAlive: false }
        },
        {
            path: '/haowu/:id/:lag',
            name: 'haowu',
            component: () => import('../page/MindexHaowu.vue'),
            meta: { title: 'ALL FIELDS 趣野好物', keepAlive: false }
        },
        
        {
            path: '/fengxianglist/:id',
            name: 'fengxianglist',
            component: () => import('../page/MindexFengxianglist.vue'),
            meta: { title: 'ALL FIELDS 趣野风向列表', keepAlive: false }
        },
        {
            path: '/fengxianglist/:id/:lag',
            name: 'fengxianglist',
            component: () => import('../page/MindexFengxianglist.vue'),
            meta: { title: 'ALL FIELDS 趣野风向列表', keepAlive: false }
        },

        
        {
            path: '/fengxianglistm/:id',
            name: 'fengxianglistm',
            component: () => import('../page/MindexFengxianglistM.vue'),
            meta: { title: 'ALL FIELDS 趣野风向列表', keepAlive: false }
        },
        {
            path: '/fengxianglistm/:id/:lag',
            name: 'fengxianglistm',
            component: () => import('../page/MindexFengxianglistM.vue'),
            meta: { title: 'ALL FIELDS 趣野风向列表', keepAlive: false }
        },
        
        {
            path: '/clublist/:id',
            name: 'clublist',
            component: () => import('../page/MindexClublist.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB列表', keepAlive: false }
        },
        {
            path: '/clublist/:id/:lag',
            name: 'clublist',
            component: () => import('../page/MindexClublist.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB列表', keepAlive: false }
        },

        
        {
            path: '/clublistm/:id',
            name: 'clublistm',
            component: () => import('../page/MindexClublistM.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB列表', keepAlive: false }
        },
        {
            path: '/clublistm/:id/:lag',
            name: 'clublistm',
            component: () => import('../page/MindexClublistM.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB列表', keepAlive: false }
        },

        
        {
            path: '/membersmag',
            name: 'membersmag',
            component: () => import('../page/MembersList.vue'),
            meta: { title: '前端用户管理', keepAlive: false }
        },


        {
            path: '/showsmag/bm',
            name: 'showsmag/bm',
            component: () => import('../page/BaomingList.vue'),
            meta: { title: '展商报名管理', keepAlive: false }
        },

        
        {
            path: '/showlist/:id',
            name: 'showlist',
            component: () => import('../page/MindexShowlist.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW列表', keepAlive: false }
        },
        {
            path: '/showlist/:id/:lag',
            name: 'showlist',
            component: () => import('../page/MindexShowlist.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW列表', keepAlive: false }
        },
        
        {
            path: '/showlistm/:id',
            name: 'showlistm',
            component: () => import('../page/MindexShowlistM.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW列表', keepAlive: false }
        },
        {
            path: '/showlistm/:id/:lag',
            name: 'showlistm',
            component: () => import('../page/MindexShowlistM.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW列表', keepAlive: false }
        },

        {
            path: '/mdetall/:kind/:id',
            name: 'mdetall',
            component: () => import('../page/Mdetall.vue'),
            meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        },
        {
            path: '/mdetall/:kind/:id/:lag',
            name: 'mdetall',
            component: () => import('../page/Mdetall.vue'),
            meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        },

        
        {
            path: '/mdetallm/:kind/:id',
            name: 'mdetallm',
            component: () => import('../page/MdetallM.vue'),
            meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        },
        {
            path: '/mdetallm/:kind/:id/:lag',
            name: 'mdetallm',
            component: () => import('../page/MdetallM.vue'),
            meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        },


        
        // {
        //     path: '/mdetallmf/:kind/:id',
        //     name: 'mdetallmf',
        //     component: () => import('../page/MdetallMf.vue'),
        //     meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        // },
        // {
        //     path: '/mdetallmf/:kind/:id/:lag',
        //     name: 'mdetallmf',
        //     component: () => import('../page/MdetallMf.vue'),
        //     meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        // },

        
        // {
        //     path: '/mdetallf/:kind/:id',
        //     name: 'mdetallf',
        //     component: () => import('../page/Mdetallf.vue'),
        //     meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        // },
        // {
        //     path: '/mdetallf/:kind/:id/:lag',
        //     name: 'mdetallf',
        //     component: () => import('../page/Mdetallf.vue'),
        //     meta: { title: 'ALL FIELDS 详情', keepAlive: false }
        // },


        
        {
            path: '/huoban/:id',
            name: 'huoban',
            component: () => import('../page/MindexHuoban.vue'),
            meta: { title: 'ALL FIELDS 趣野伙伴', keepAlive: false }
        },
        {
            path: '/huoban/:id/:lag',
            name: 'huoban',
            component: () => import('../page/MindexHuoban.vue'),
            meta: { title: 'ALL FIELDS 趣野伙伴', keepAlive: false }
        },


        
        {
            path: '/huobanm/:id',
            name: 'huoban',
            component: () => import('../page/MindexHuobanM.vue'),
            meta: { title: 'ALL FIELDS 趣野伙伴', keepAlive: false }
        },
        {
            path: '/huobanm/:id/:lag',
            name: 'huoban',
            component: () => import('../page/MindexHuobanM.vue'),
            meta: { title: 'ALL FIELDS 趣野伙伴', keepAlive: false }
        },

        
        {
            path: '/show/:id',
            name: 'show',
            component: () => import('../page/MindexShow.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW', keepAlive: false }
        },
        {
            path: '/show/:id/:lag',
            name: 'show',
            component: () => import('../page/MindexShow.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW', keepAlive: false }
        },


        
        
        {
            path: '/showm/:id',
            name: 'showm',
            component: () => import('../page/MindexShowM.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW', keepAlive: false }
        },
        {
            path: '/showm/:id/:lag',
            name: 'showm',
            component: () => import('../page/MindexShowM.vue'),
            meta: { title: 'ALL FIELDS 趣野SHOW', keepAlive: false }
        },

        
        {
            path: '/club/:id',
            name: 'club',
            component: () => import('../page/MindexClub.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB', keepAlive: false }
        },
        {
            path: '/club/:id/:lag',
            name: 'club',
            component: () => import('../page/MindexClub.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB', keepAlive: false }
        },



        {
            path: '/clubm/:id',
            name: 'clubm',
            component: () => import('../page/MindexClubM.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB', keepAlive: false }
        },
        {
            path: '/clubm/:id/:lag',
            name: 'clubm',
            component: () => import('../page/MindexClubM.vue'),
            meta: { title: 'ALL FIELDS 趣野CLUB', keepAlive: false }
        },


        
        {
            path: '/index3',
            name: 'index3',
            component: () => import('../page/Index3.vue'),
            meta: { title: '首页3', keepAlive: true }
        },


        {
            path: '/login',
            name: 'login',
            component: () => import('../page/Login.vue'),
            meta: { title: '登录', keepAlive: false }
        },




        {
            path: '/classmag/kind',
            name: 'classmagkind',
            component: () => import('../page/classKind.vue'),
            meta: { title: '种类管理', keepAlive: false }
        },

        {
            path: '/teammag/list',
            component: () => import('../page/TeamMag.vue'),
            meta: { title: '管理员管理', keepAlive: false }
        },

        {
            path: '/basemag',
            component: () => import('../page/BaseMag.vue'),
            meta: { title: '基础设置', keepAlive: false }
        },

        {
            path: '/baseenmag',
            component: () => import('../page/BaseEnMag.vue'),
            meta: { title: '英文基础设置', keepAlive: false }
        },




        {
            path: '/swipermag/:kind',
            name: 'swipermag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: 'ALL FIELDS 首页轮播', keepAlive: false }
        },
        {
            path: '/fengxiangmag/:kind',
            name: 'fengxiangmag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: 'ALL FIELDS 趣野风向', keepAlive: false }
        },

        

        {
            path: '/enmag/:kind',
            name: 'enmag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: 'ALL FIELDS 英文版管理', keepAlive: false }
        },
        

        {
            path: '/haowumag/:kind',
            name: 'haowumag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: 'ALL FIELDS 趣野好物', keepAlive: false }
        },
        
        
        {
            path: '/showsmag/now/:kind',
            name: 'showsmag/now',
            component: () => import('../page/ShowList.vue'),
            meta: { title: '现场SHOW什么', keepAlive: false }
        },
        
        
        {
            path: '/showsmag/:kind',
            name: 'showsmag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: 'SHOW列表', keepAlive: false }
        },
        
        
        {
            path: '/clubmag/:kind',
            name: 'clubmag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: 'SHOW列表', keepAlive: false }
        },
        
        {
            path: '/clubmag/hj/:kind',
            name: 'clubmag/hj',
            component: () => import('../page/ClubList.vue'),
            meta: { title: 'CLUB活动报名', keepAlive: false }
        },


        // {
        //     path: '/clubmag/:kind',
        //     name: 'clubmag',
        //     component: () => import('../page/ClubList.vue'),
        //     meta: { title: 'Club列表', keepAlive: false }
        // },
        
        {
            path: '/casemag',
            name: 'casemag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: '案例列表', keepAlive: false }
        },
        {
            path: '/casemag/:kind',
            name: 'casemag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: '案例列表', keepAlive: false }
        },
        {
            path: '/awardsmag',
            name: 'awardsmag',
            component: () => import('../page/AwardsList.vue'),
            meta: { title: '获奖管理', keepAlive: false }
        },
        {
            path: '/friendmag',
            name: 'friendmag',
            component: () => import('../page/FriendList.vue'),
            meta: { title: '案例列表', keepAlive: false }
        },

        
        // {
        //     path: '/swiperdmag/:kind',
        //     name: 'swiperdmag',
        //     component: () => import('../page/SwiperList.vue'),
        //     meta: { title: '案例列表', keepAlive: false }
        // },


        
        {
            path: '/friendmag/:kind',
            name: 'friendmag',
            component: () => import('../page/FriendList.vue'),
            meta: { title: '伙伴列表', keepAlive: false }
        },


        {
            path: '/index',
            name: 'index',
            component: () => import('../page/Index.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: true }
        },
        {
            path: '/index/:lag',
            name: 'index',
            component: () => import('../page/Index.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: true }
        },
        

        {
            path: '/indexen',
            name: 'indexm',
            component: () => import('../page/IndexEn.vue'),
            meta: { title: 'ALL FIELDS', keepAlive: true }
        },

        
        {
            path: '/pdetall/:id',
            name: 'index',
            component: () => import('../page/Pdetall.vue'),
            meta: { title: '案例', keepAlive: false }
        },
        {
            path: '/pdetall/:id/:lag',
            name: 'index',
            component: () => import('../page/Pdetall.vue'),
            meta: { title: '案例', keepAlive: false }
        },


        
        
        {
            path: '/adetall/:id',
            name: 'index',
            component: () => import('../page/Adetall.vue'),
            meta: { title: '荣誉', keepAlive: false }
        },
        {
            path: '/adetall/:id/:lag',
            name: 'index',
            component: () => import('../page/Adetall.vue'),
            meta: { title: '荣誉', keepAlive: false }
        },




        {
            path: '/plist/:from',
            name: 'index',
            component: () => import('../page/Plist.vue'),
            meta: { title: '案例', keepAlive: true }
        },
        {
            path: '/plist/:from/:lag',
            name: 'index',
            component: () => import('../page/Plist.vue'),
            meta: { title: '案例', keepAlive: true }
        },


        {
            path: '/clist',
            name: 'clist',
            component: () => import('../page/Clist.vue'),
            meta: { title: 'AI', keepAlive: false }
        },
        {
            path: '/mindex',
            name: 'mindex',
            component: () => import('../page/mindex.vue'),
            meta: { title: 'AI', keepAlive: false }
        },
        {
            path: '/aimindex',
            name: 'aimindex',
            component: () => import('../page/aimindex.vue'),
            meta: { title: 'AI', keepAlive: false }
        },


        
        {
            path: '/payed/:no',
            name: 'payed',
            component: () => import('../page/payed.vue'),
            meta: { title: 'ALL FIELDS 付款', keepAlive: false }
        },
        {
            path: '/payview/:no',
            name: 'payview',
            component: () => import('../page/payview.vue'),
            meta: { title: 'ALL FIELDS 付款', keepAlive: false }
        },

    ]
});


 
router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
    if (to.meta.title) {//判断是否有标题
        // @ts-ignore
        document.title = to.meta.title
    }
    next()//执行进入路由，如果不写就不会进入目标页
})

export default router;
