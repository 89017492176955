import {
  fetchMemberinfo
} from "./api/index"
// 定义一些公共的属性和方法
// var baseUrl = 'https://cdn.moheweb.com/vdb/lweb/api/';
var baseUrl = '/api/';
var dev = true;

// import { forEach } from "core-js/core/array";

// const baseUrl = '/vdb/gweb/api2/';
// const baseUrl = '/vdb/gweb/api/';

// const countries = require("assets/json/countries.json");
// const states = require("assets/json/states.json");

var jfgz = [
  { name: '成为会员', jf: '5' },
  { name: '完整注册', jf: '20' },
  { name: '邀请朋友注册成功', jf: '20' },
  { name: '购物', jf: '1' },
  { name: '活动报名', jf: '50' },
]

function resetMemberinfo() {
  console.log("global.resetMemberinfo()");
  let params = {};
  fetchMemberinfo(params).then((res) => {
    let data = res.results;
    setMemberInfo(data);
  });
}

var hwkindlist = [
  { id: 1, name: "运动" },
  { id: 2, name: "露营" },
  { id: 3, name: "娱乐" }
];

function gethwkindname(id) {
  let name = '';
  hwkindlist.map((item) => {
    if (item.id == id) {
      name = item.name;
    }
  })
  return name;
}

function setbaseUrl(key) {
  if (key == 0) {
    this.baseUrl = '/api/';
  } else if (key == 1) {
    this.baseUrl = 'https://cdn.moheweb.com/vdb/lwebf/api/';
  }
}

function setdev(key) {
  if (key == 0) {
    this.dev = true;
  } else if (key == 1) {
    this.dev = false;
  }
}

var loginB = localStorage.getItem('ks_login') !== null ? localStorage.getItem('ks_login') : false;
function commonFun() {
  console.log("公共方法")
}
function setlocalStorage(key, value) {
  localStorage.setItem(key, value);
}
function getlocalStorage(key) {
  return localStorage.getItem(key) !== null ? localStorage.getItem(key) : '';
}
function setPageKey(key) {
  localStorage.setItem('ks_pageKey', key);
}
function getPageKey() {
  let ks_pageKey = localStorage.getItem('ks_pageKey') !== null ? localStorage.getItem('ks_pageKey') : 'index'
  return ks_pageKey;
}
function setLoginInfo(ft, userName, realName, uid, level, keys, rules, city, citycode, dis, discode, unid, unname, photourl) {
  localStorage.setItem('ksa_login', ft);
  localStorage.setItem('ksa_userName', userName);
  localStorage.setItem('ksa_realName', realName);
  localStorage.setItem('ksa_uid', uid);
  localStorage.setItem('ksa_level', level);
  localStorage.setItem('ksa_authkeys', keys);
  localStorage.setItem('ksa_authrules', rules);
  localStorage.setItem('ksa_city', city);
  localStorage.setItem('ksa_citycode', citycode);
  localStorage.setItem('ksa_dis', dis);
  localStorage.setItem('ksa_discode', discode);
  localStorage.setItem('ksa_unid', unid);
  localStorage.setItem('ksa_unname', unname);
  localStorage.setItem('ksa_photourl', photourl);
}
function clearLoginInfo() {
  localStorage.removeItem("ksa_login");
  localStorage.removeItem("ksa_userName");
  localStorage.removeItem("ksa_realName");
  localStorage.removeItem("ksa_uid");
  localStorage.removeItem("ksa_level");
  localStorage.removeItem('ksa_authkeys');
  localStorage.removeItem('ksa_authrules');
  localStorage.removeItem('ksa_city');
  localStorage.removeItem('ksa_citycode');
  localStorage.removeItem('ksa_dis');
  localStorage.removeItem('ksa_discode');
  localStorage.removeItem('ksa_unid');
  localStorage.removeItem('ksa_unname');
  localStorage.removeItem('ksa_photourl');
}
function clearTdata() {
  localStorage.removeItem("ks_tdata");
  localStorage.removeItem("ks_finish");
}

function setTdataInfo(tdata) {
  let ks_finish = localStorage.getItem('ks_finish') !== null ? localStorage.getItem('ks_finish') : 'false';
  if (ks_finish == 'false') {
    localStorage.setItem('ks_tdata', JSON.stringify(tdata));
  }

}
function setFinish() {
  // console.log(JSON.stringify(tdata));
  localStorage.setItem('ks_finish', 'true');
}



function getLoginA() {
  return {
    ksa_login: localStorage.getItem('ksa_login') !== null ? localStorage.getItem('ksa_login') : 'false',
    ksa_userName: localStorage.getItem('ksa_userName') !== null ? localStorage.getItem('ksa_userName') : '',
    ksa_realName: localStorage.getItem('ksa_realName') !== null ? localStorage.getItem('ksa_realName') : '',
    ksa_uid: localStorage.getItem('ksa_uid') !== null ? localStorage.getItem('ksa_uid') : '',
    ksa_level: localStorage.getItem('ksa_level') !== null ? localStorage.getItem('ksa_level') : '',
    ksa_authkeys: localStorage.getItem('ksa_authkeys') !== null ? localStorage.getItem('ksa_authkeys') : '',
    ksa_authrules: localStorage.getItem('ksa_authrules') !== null ? localStorage.getItem('ksa_authrules') : '',
    ksa_city: localStorage.getItem('ksa_city') !== null ? localStorage.getItem('ksa_city') : '',
    ksa_citycode: localStorage.getItem('ksa_citycode') !== null ? localStorage.getItem('ksa_citycode') : '',
    ksa_dis: localStorage.getItem('ksa_dis') !== null ? localStorage.getItem('ksa_dis') : '',
    ksa_discode: localStorage.getItem('ksa_discode') !== null ? localStorage.getItem('ksa_discode') : '',
    ksa_unid: localStorage.getItem('ksa_unid') !== null ? localStorage.getItem('ksa_unid') : '',
    ksa_unname: localStorage.getItem('ksa_unname') !== null ? localStorage.getItem('ksa_unname') : '',
    ksa_photourl: localStorage.getItem('ksa_photourl') !== null ? localStorage.getItem('ksa_photourl') : '',
  }
}

function getTdata() {
  let ks_tdata = localStorage.getItem('ks_tdata') !== null ? localStorage.getItem('ks_tdata') : '{}'
  return JSON.parse(ks_tdata);
}

function searchrule(key) {
  let rule = '0';
  if (localStorage.getItem('ksa_authkeys') != null) {
    const authkeyary = localStorage.getItem('ksa_authkeys').split(',');
    const authruleary = localStorage.getItem('ksa_authrules').split(',');
    const level = localStorage.getItem('ksa_level') !== null ? localStorage.getItem('ksa_level') : '';
    authkeyary.map((item, index) => {
      if (item == key) {
        rule = authruleary[index];
      } else if (item != key && item.indexOf(key) > -1 && authruleary[index] > 0) {
        rule = 100;
      }
    })
    if (level == '0') {
      rule = 3;
    }
  }
  return rule;
}

function checkrule() {
  return searchrule(getPageKey());
}


function setIndexDclass(classtext) {
  localStorage.setItem('IndexDclass', classtext);
}
function getIndexDclass() {
  let classtext = localStorage.getItem('IndexDclass') !== null ? localStorage.getItem('IndexDclass') : '';
  return classtext;
}


function getSider() {
  const sider = [
    { title: "基础管理", key: "basemag", rule: searchrule('basemag'), level: [1, 2, 3, 4], icon: "", component: "basemag" },
    // { title: "英文基础管理", key: "baseenmag", rule: searchrule('baseenmag'), level: [1, 2, 3, 4], icon: "", component: "baseenmag" },
    // { title: "客户管理", key: "friendmag", rule: searchrule('friendmag'), level: [1, 2, 3, 4], icon: "", component: "friendmag" },


    {
      title: "英文版管理", key: "enmag", rule: searchrule('enmag'), level: [1], icon: "", component: "enmag", list: [
        { title: "EN首页轮播", key: "enmag/900", rule: searchrule('enmag'), level: [1, 2, 3, 4], icon: "", component: "enmag" },
        { title: "英文版文档", key: "enmag/9000", rule: searchrule('enmag'), level: [1, 2, 3, 4], icon: "", component: "enmag" },
        { title: "英文版品牌", key: "enmag/9100", rule: searchrule('enmag'), level: [1, 2, 3, 4], icon: "", component: "enmag" },
        { title: "英文版精彩瞬间", key: "enmag/9200", rule: searchrule('enmag'), level: [1, 2, 3, 4], icon: "", component: "enmag" }
      ]
    },
    {
      title: "首页轮播管理", key: "swipermag", rule: searchrule('swipermag'), level: [1], icon: "", component: "swipermag", list: [
        { title: "首页轮播", key: "swipermag/90", rule: searchrule('swipermag'), level: [1, 2, 3, 4], icon: "", component: "swipermag" },
        // { title: "EN首页轮播", key: "swipermag/900", rule: searchrule('swipermag'), level: [1, 2, 3, 4], icon: "", component: "swipermag" },
        { title: "广告轮播", key: "swipermag/91", rule: searchrule('swipermag'), level: [1, 2, 3, 4], icon: "", component: "swipermag" },
      ]
    },
    {
      title: "趣野风向", key: "fengxiangmag", rule: searchrule('fengxiangmag'), level: [1], icon: "", component: "fengxiangmag", list: [
        { title: "FM.看看世界", key: "fengxiangmag/00", rule: searchrule('fengxiangmag'), level: [1, 2, 3, 4], icon: "", component: "fengxiangmag" },
        { title: "趣野故事会", key: "fengxiangmag/01", rule: searchrule('fengxiangmag'), level: [1, 2, 3, 4], icon: "", component: "fengxiangmag" },
        { title: "趣野视角", key: "fengxiangmag/02", rule: searchrule('fengxiangmag'), level: [1, 2, 3, 4], icon: "", component: "fengxiangmag" },
      ]
    },

    {
      title: "趣野好物", key: "haowumag", rule: searchrule('haowumag'), level: [1], icon: "", component: "haowumag", list: [
        { title: "首页推荐", key: "haowumag/20", rule: searchrule('haowumag'), level: [1, 2, 3, 4], icon: "", component: "haowumag" },
        { title: "好物推荐", key: "haowumag/21", rule: searchrule('haowumag'), level: [1, 2, 3, 4], icon: "", component: "haowumag" },
      ]
    },
    // {
    //   title: "趣野风向", key: "fengxiangmag", rule: searchrule('fengxiangmag'), level: [1], icon: "", component: "fengxiangmag", list: [
    //     { title: "FM.看看世界", key: "fengxiangmag/00", rule: searchrule('fengxiangmag'), level: [1, 2, 3, 4], icon: "", component: "fengxiangmag" },
    //     { title: "趣野故事会", key: "fengxiangmag/01", rule: searchrule('fengxiangmag'), level: [1, 2, 3, 4], icon: "", component: "fengxiangmag" },
    //     { title: "趣野视角", key: "fengxiangmag/02", rule: searchrule('fengxiangmag'), level: [1, 2, 3, 4], icon: "", component: "fengxiangmag" },
    //   ]
    // },


    {
      title: "趣野SHOW", key: "showsmag", rule: searchrule('showsmag'), level: [1], icon: "", component: "showmag", list: [
        { title: "现场SHOW什么", key: "showsmag/now/33", rule: searchrule('showsmag'), level: [1, 2, 3, 4], icon: "", component: "showsmag/now" },
        { title: "新鲜SHOW闻", key: "showsmag/31", rule: searchrule('showsmag'), level: [1, 2, 3, 4], icon: "", component: "showsmag" },
        { title: "Ta们在SHOW", key: "showsmag/32", rule: searchrule('showsmag'), level: [1, 2, 3, 4], icon: "", component: "showsmag" },
        { title: "参展申请管理", key: "showsmag/bm", rule: searchrule('showsmag'), level: [1, 2, 3, 4], icon: "", component: "showsmag/bm" },
      ]
    },


    {
      title: "趣野CLUB", key: "clubmag", rule: searchrule('casemag'), level: [1], icon: "", component: "casemag", list: [


        { title: "精彩回放", key: "clubmag/41", rule: searchrule('clubmag'), level: [1, 2, 3, 4], icon: "", component: "casemag" },

        { title: "CLUB活动报名", key: "clubmag/hj/40", rule: searchrule('clubmag'), level: [1, 2, 3, 4], icon: "", component: "clubmag" },

        // { title: "案例管理", key: "casemag", rule: searchrule('casemag'), level: [1, 2, 3, 4], icon: "", component: "casemag" },
        // { title: "获奖管理", key: "awardsmag", rule: searchrule('awardsmag'), level: [1, 2, 3, 4], icon: "", component: "awardsmag" },
        // { title: "分类管理", key: "classmag/kind", rule: searchrule('classmagkind'), level: [1, 2, 3, 4], icon: "", component: "classmagkind" },
      ]
    },
    {
      title: "趣野伙伴", key: "friendmag", rule: searchrule('friendmag'), level: [1], icon: "", component: "friendmag", list: [
        { title: "先锋玩家", key: "friendmag/10", rule: searchrule('friendmag'), level: [1, 2, 3, 4], icon: "", component: "friendmag" },
        { title: "合作品牌", key: "friendmag/11", rule: searchrule('friendmag'), level: [1, 2, 3, 4], icon: "", component: "friendmag" },
      ]
    },
    // {
    //   title: "文档", key: "downloadmag", rule: searchrule('downloadmag'), level: [1], icon: "", component: "downloadmag", list: [
    //     { title: "英文版文档", key: "downloadmag/9000", rule: searchrule('downloadmag'), level: [1, 2, 3, 4], icon: "", component: "downloadmag" }
    //   ]
    // },
    { title: "趣野会员管理", key: "membersmag", rule: searchrule('membersmag'), level: [1, 2, 3, 4], icon: "", component: "membersmag" },
    {
      title: "后台权限管理", key: "teammag", rule: searchrule('teammag'), level: [1], icon: "", component: "teammag", list: [
        // { title: "单位管理", key: "teammag/unit", rule: searchrule('infomag/unit'), level: [1, 2, 3], icon: "", component: "" },
        // { title: "组织结构", key: "teammag/org", rule: searchrule('infomag/org'), level: [1, 2, 3], icon: "", component: "" },
        { title: "账号人员管理", key: "teammag/list", rule: searchrule('infomag/list'), level: [1, 2, 3], icon: "", component: "" },
      ]
    },
  ];
  return sider;
}

const inpbasedate = [
  {
    code: "nickName",
    name: "Nick name",
    required: true,
    cname: "昵称",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: false,
  },
  {
    code: "sex",
    name: "Appellation",
    required: true,
    cname: "称谓",
    kind: "select",
    placeholder: "Please enter appellation",
    cplaceholder: "请选择的称谓",
    values: [
      { cname: "先生", name: "Mr", id: '1' },
      { cname: "女士", name: "Ms", id: '0' },
    ],
    columns: ["先生", "女士"],
    value: "",
    del: false,
  },
  {
    code: "birthday",
    name: "Birthday",
    required: true,
    cname: "出生日期",
    kind: "date",
    value: "",
    defaultValue: null,
    del: false,
  },
  {
    code: "country",
    name: "country/state",
    required: true,
    cname: "国家/地区",
    kind: "selectcountry",
    placeholder: "Please select",
    cplaceholder: "请选择",
    values: [],
    columns: [],
    value: "",
    del: false,
  },
  {
    code: "PC",
    name: "Province/City",
    required: true,
    cname: "省份/城市",
    kind: "selectPC",
    placeholder: "Please select",
    cplaceholder: "请选择",
    values: [],
    columns: [],
    value: "",
    del: false,
  },
  {
    code: "name",
    name: "Full name",
    required: true,
    cname: "姓名",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: false,
  },
  {
    code: "idtype",
    name: "ID type",
    required: true,
    cname: "证件类型",
    kind: "select",
    placeholder: "Please select",
    cplaceholder: "请选择",
    values: [
      { cname: "身份证", name: "ID card", id: '0' },
      { cname: "港澳台通行证", name: "ID card", id: '1' },
      { cname: "护照", name: "ID card", id: '2' }
    ],
    columns: ["身份证"],
    value: "",
    del: false,
  },
  {
    code: "idnumber",
    name: "ID number",
    required: true,
    cname: "证件号码",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: false,
  },
  {
    code: "industry",
    name: "Industry",
    required: false,
    cname: "行业",
    kind: "select",
    placeholder: "Please select",
    cplaceholder: "请选择",
    values: [
      { cname: "制造", name: "Manage", id: '0' },
      { cname: "建筑", name: "Engineer", id: '1' },
      { cname: "零售", name: "Engineer", id: '2' },
      { cname: "软件信息", name: "Engineer", id: '3' },
      { cname: "金融", name: "Engineer", id: '4' },
      { cname: "商业服务", name: "Engineer", id: '5' },
      { cname: "教育", name: "Engineer", id: '6' },
      { cname: "医疗", name: "Engineer", id: '7' },
      { cname: "体育", name: "Engineer", id: '8' },
      { cname: "文化/娱乐", name: "Engineer", id: '9' },
      { cname: "公共管理", name: "Engineer", id: '10' },
      { cname: "组织机构", name: "Engineer", id: '11' },
      { cname: "其他", name: "Engineer", id: '12' },
    ],
    columns: ["经理", "工程师"],
    value: "",
    del: false,
  },
  {
    code: "company",
    name: "Company",
    required: true,
    cname: "公司",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: true,
  },
  {
    code: "duties",
    name: "Duties",
    required: false,
    cname: "职务",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: false,
  },
  {
    code: "as",
    name: "Annual salary",
    required: true,
    cname: "年薪",
    kind: "select",
    placeholder: "Please select",
    cplaceholder: "请选择",
    values: [
      { cname: "15万以内", name: "15万以内", id: '0' },
      { cname: "15-30万", name: "15-30万", id: '1' },
      { cname: "30-50万", name: "30-50万", id: '2' },
      { cname: "50-100万", name: "50-100万", id: '3' },
      { cname: "100万以上", name: "100万以上", id: '4' },
    ],
    value: "",
    del: true,
  },
  {
    code: "years",
    name: "Years",
    required: true,
    cname: "户外年限",
    kind: "select",
    placeholder: "Please select",
    cplaceholder: "请选择",
    values: [
      { cname: "0经验", name: "0经验", id: '0' },
      { cname: "1年以内", name: "1年以内", id: '1' },
      { cname: "1-3年", name: "1-3年", id: '2' },
      { cname: "3-5年", name: "3-5年", id: '3' },
      { cname: "5-10年", name: "5-10年", id: '4' },
      { cname: "10年以上", name: "10年以上", id: '5' },
    ],
    value: "",
    del: true,
  },
];









function getInpBDvalue(code, value) {

  let val = '';

  inpbasedate.map((item, index) => {
    if (item.code == code) {
      if (item.kind == 'select') {
        item.values.map((item2, index2) => {
          if (item2.id == value) {
            val = item2.cname;
          }
        })
      } else {
        val = value;
      }
    }
  })
  if (val == '') {
    val = value;
  }

  return val;

}

const inpLikedate = [
  {
    code: "like0",
    name: "Interested outdoor sections (multiple choices)",
    cname: "感兴趣的户外板块（多选）",
    required: true,
    value: "",
    multiple: true,
    values: [
      { cname: "露营", name: "", code: "露营", selected: false },
      { cname: "垂钓", name: "", code: "垂钓", selected: false },
      { cname: "徒步", name: "", code: "徒步", selected: false },
      { cname: "跑步", name: "", code: "跑步", selected: false },
      { cname: "骑行", name: "", code: "骑行", selected: false },
      { cname: "越野", name: "", code: "越野", selected: false },
      { cname: "滑板", name: "", code: "滑板", selected: false },
      { cname: "飞盘", name: "", code: "飞盘", selected: false },
      { cname: "攀岩", name: "", code: "攀岩", selected: false },
      { cname: "水上项目", name: "", code: "水上项目", selected: false },
      { cname: "冰雪项目", name: "", code: "冰雪项目", selected: false },
      { cname: "儿童运动", name: "", code: "儿童运动", selected: false },
      { cname: "其他", name: "", code: "其他", selected: false },
    ],
    other: '',
    del: false,
  },
  {
    code: "like2",
    name: "By logging in, you agree to the 'Quye Unlimited Service Agreement'",
    cname: "对何种形式的线下活动感兴趣？（多选）",
    required: true,
    value: "",
    multiple: true,
    values: [
      {
        cname: "展会/市集",
        name: "",
        code: "展会/市集",
        selected: false,
      },
      { cname: "体验营", name: "", code: "体验营", selected: false },
      { cname: "训练营", name: "", code: "训练营", selected: false },
      { cname: "课程培训", name: "", code: "课程培训", selected: false },
      { cname: "比赛", name: "", code: "比赛", selected: false },
      { cname: "文体旅游", name: "", code: "文体旅游", selected: false },
      { cname: "团队拓展", name: "", code: "团队拓展", selected: false },
      { cname: "其他", name: "", code: "其他", selected: false },
    ],
    other: '',
    del: false,
  },
  {
    code: "like4",
    name: "What are the expected gains through ALL FIELDS? (Multiple choices)",
    cname: "期望通过ALL FIELDS获得何种收获？（多选）",
    required: true,
    value: "",
    multiple: true,
    values: [
      {
        cname: "快速进入户外圈子",
        name: "",
        code: "快速进入户外圈子",
        selected: false,
      },
      {
        cname: "参与丰富多元的户外活动",
        name: "",
        code: "参与丰富多元的户外活动",
        selected: false,
      },
      {
        cname: "了解一手户外潮流文化动向",
        name: "",
        code: "了解一手户外潮流文化动向",
        selected: false,
      },
      {
        cname: "结交志同道合的户外搭子",
        name: "",
        code: "结交志同道合的户外搭子",
        selected: false,
      },
      {
        cname: "与达人/大咖面对面的交流机会",
        name: "",
        code: "与达人/大咖面对面的交流机会",
        selected: false,
      },
      {
        cname: "探索户外文化和理念",
        name: "",
        code: "探索户外文化和理念",
        selected: false,
      },
      {
        cname: "筛选购买适合的户外用品",
        name: "",
        code: "筛选购买适合的户外用品",
        selected: false,
      },
    ],
    other: '',
    del: false,
  },
  {
    code: "like5",
    name: "Annual investment in outdoor activities?",
    cname: "每年在户外相关的投入？",
    required: true,
    value: "",
    multiple: false,
    values: [
      {
        cname: "0.5万以内",
        name: "",
        code: "0.5万以内",
        selected: false,
      },
      { cname: "0.5-2万", name: "", code: "0.5-2万", selected: false },
      { cname: "2-5万", name: "", code: "2-5万", selected: false },
      { cname: "5-10万", name: "", code: "5-10万", selected: false },
      { cname: "10万以上", name: "", code: "10万以上", selected: false },
    ],
    other: '',
    del: true,
  },
  {
    code: "like1",
    name: "What platform is the most commonly used to learn about outdoor content? (Multiple choices)",
    cname: "最常通过何种平台了解户外内容？（多选）",
    required: false,
    value: "",
    multiple: true,
    values: [
      { cname: "社交平台", name: "", code: "社交平台", selected: false },
      { cname: "垂直媒体", name: "", code: "垂直媒体", selected: false },
      { cname: "KOL/KOC", name: "", code: "KOL/KOC", selected: false },
      {
        cname: "社群/俱乐部",
        name: "",
        code: "社群/俱乐部",
        selected: false,
      },
      { cname: "品牌方", name: "", code: "品牌方", selected: false },
      { cname: "行业组织", name: "", code: "行业组织", selected: false },
      { cname: "其他", name: "", code: "其他", selected: false },
    ],
    other: '',
    del: true,
  },
  {
    code: "like3",
    name: "What is the way to learn about ALL FIELDS?",
    cname: "通过何种途径了解到ALL FIELDS？",
    required: false,
    value: "",
    multiple: false,
    values: [
      { cname: "社交平台", name: "", code: "社交平台", selected: false },
      { cname: "搜索引擎", name: "", code: "搜索引擎", selected: false },
      { cname: "朋友推荐", name: "", code: "朋友推荐", selected: false },
      {
        cname: "品牌方推荐",
        name: "",
        code: "品牌方推荐",
        selected: false,
      },
      { cname: "达人分享", name: "", code: "达人分享", selected: false },
      { cname: "其他", name: "", code: "其他", selected: false },
    ],
    other: '',
    del: false,
  },
  {
    code: "like6",
    name: "Who are you willing to experience outdoor life with? (Multiple choices)",
    cname: "愿意跟谁一起体验户外生活？（多选）",
    required: false,
    value: "",
    multiple: true,
    values: [
      { cname: "自己", name: "", code: "自己", selected: false },
      { cname: "家人", name: "", code: "家人", selected: false },
      { cname: "朋友", name: "", code: "朋友", selected: false },
      {
        cname: "同事/同学",
        name: "",
        code: "同事/同学",
        selected: false,
      },
      {
        cname: "达人/大咖",
        name: "",
        code: "达人/大咖",
        selected: false,
      },
      { cname: "陌生人", name: "", code: "陌生人", selected: false },
      { cname: "其他", name: "", code: "其他", selected: false },
    ],
    other: '',
    del: false,
  },
];



function getInpBMvalue(code, value) {

  let val = '';

  inpbmdate.map((item, index) => {
    if (item.code == code) {
      if (item.kind == 'select') {
        item.values.map((item2, index2) => {
          if (item2.id == value) {
            val = item2.cname;
          }
        })
      } else {
        val = value;
      }
    }
  })
  if (val == '') {
    val = value;
  }

  return val;

}
const inpbmdate = [
  {
    code: "companyzh",
    required: true,
    name: "corporate name zh",
    cname: "公司名称（中文）",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请输入",
    value: "",
    del: false,
  },
  {
    code: "companyen",
    required: false,
    name: "corporate name en",
    cname: "公司名称（英文）",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请输入",
    value: "",
    del: false,
  },
  {
    code: "mp",
    required: true,
    name: "Main products",
    cname: "主营",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请输入",
    value: "",
    del: false,
  },
  {
    code: "web",
    required: false,
    name: "WEB",
    cname: "公司网站",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请输入",
    value: "",
    del: false,
  },
  {
    code: "wanted",
    name: "Booth Type",
    required: true,
    cname: "意向展位类型",
    kind: "select",
    placeholder: "Please select",
    cplaceholder: "请选择",
    values: [
      { cname: "标摊", name: "Construct", id: '0' },
      { cname: "光地", name: "Construct", id: '1' }
    ],
    value: "",
    del: false,
  },
  {
    code: "iarea",
    required: false,
    name: "intention area",
    cname: "意向面积(m²)",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请输入",
    value: "",
    del: false,
  },
  {
    code: "name",
    required: true,
    name: "name",
    cname: "姓名",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请输入",
    value: "",
    del: false,
  },
  {
    code: "tel",
    required: true,
    name: "tel",
    cname: "手机",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请输入",
    value: "",
    del: false,
  },
  {
    code: "duties",
    required: true,
    name: "Duties",
    cname: "职务",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: false,
  },
  {
    code: "adr",
    required: true,
    name: "Address",
    cname: "地址",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: false,
  },
  {
    code: "email",
    required: true,
    name: "Email",
    cname: "电子邮件",
    kind: "input",
    placeholder: "Please enter",
    cplaceholder: "请填写",
    value: "",
    del: false,
  },
];

// const inpbmdate = [
//   {
//     code: "companyzh",
//     name: "corporate name zh",
//     cname: "公司名称（中文）",
//     kind: "input",
//     placeholder: "Please enter",
//     cplaceholder: "请输入",
//     value: "",
//   },
//   {
//     code: "companyen",
//     name: "corporate name en",
//     cname: "公司名称（英文）",
//     kind: "input",
//     placeholder: "Please enter",
//     cplaceholder: "请输入",
//     value: "",
//   },
//   {
//     code: "mp",
//     name: "Main products",
//     cname: "主营",
//     kind: "select",
//     placeholder: "Please select",
//     cplaceholder: "请选择",
//     values: [
//       { cname: "建造", name: "Construct", id: '0' },
//       { cname: "软件", name: "Software", id: '1' },
//     ],
//     value: "",
//   },
//   {
//     code: "PC",
//     name: "Province/City",
//     cname: "省份/城市",
//     kind: "selectPC",
//     placeholder: "Please select",
//     cplaceholder: "请选择省份",
//     values: [],
//     value: "",
//   },
//   {
//     code: "idtype",
//     name: "ID type",
//     cname: "证件类型",
//     kind: "select",
//     placeholder: "Please select",
//     cplaceholder: "请选择",
//     values: [{ cname: "身份证", name: "ID card", id: '0' }],
//     value: "",
//   },
//   {
//     code: "idnumber",
//     name: "ID number",
//     cname: "证件号码",
//     kind: "input",
//     placeholder: "Please enter",
//     cplaceholder: "请填写",
//     value: "",
//   },
//   {
//     code: "industry",
//     name: "Industry",
//     cname: "行业",
//     kind: "select",
//     placeholder: "Please select",
//     cplaceholder: "请选择",
//     values: [
//       { cname: "建造", name: "Construct", id: '0' },
//       { cname: "软件", name: "Software", id: '1' },
//     ],
//     value: "",
//   },
//   {
//     code: "company",
//     name: "Company",
//     cname: "公司",
//     kind: "input",
//     placeholder: "Please enter",
//     cplaceholder: "请填写",
//     value: "",
//   },
//   {
//     code: "duties",
//     name: "Duties",
//     cname: "职务",
//     kind: "input",
//     placeholder: "Please enter",
//     cplaceholder: "请填写",
//     value: "",
//   },
//   {
//     code: "as",
//     name: "Annual salary",
//     cname: "年薪",
//     kind: "select",
//     placeholder: "Please select",
//     cplaceholder: "请选择",
//     values: [
//       { cname: "0-60000", name: "0-60000", id: '0' },
//       { cname: "60000-100000", name: "60000-100000", id: '1' },
//       { cname: "100000-200000", name: "100000-200000", id: '2' },
//       { cname: "300000-500000", name: "300000-500000", id: '3' },
//       { cname: "500000", name: "500000", id: '4' },
//     ],
//     value: "",
//   },
//   {
//     code: "years",
//     name: "Years",
//     cname: "户外年限",
//     kind: "select",
//     placeholder: "Please select",
//     cplaceholder: "请选择",
//     values: [
//       { cname: "0", name: "0", id: '0' },
//       { cname: "1", name: "1", id: '1' },
//       { cname: "2", name: "2", id: '2' },
//       { cname: "3", name: "3", id: '3' },
//       { cname: "4", name: "4", id: '4' },
//       { cname: "5", name: "5", id: '5' },
//     ],
//     value: "",
//   },
// ];


function getinpName(code, value) {

}

function setauth(key) {
  localStorage.setItem('member_auth', key);
}

function setMemberInfo(data) {

  let isEmpty = 0;

  for (let key in data) {
    // if (key != 'other0' && key != 'other1' && key != 'other2' && key != 'other3' && key != 'other6' && key != 'avatarUrl' && key != 'email' && key != 'photo' && key != 'openid' && data[key] == '') {
    if (key != 'other0' && key != 'other1' && key != 'other2' && key != 'other3' && key != 'other6' && key != 'avatarUrl' && key != 'email' && key != 'photo' && key != 'openid' && data[key] == '') {
      let itemdatalist = [];
      itemdatalist = inpbasedate.filter((item) => {
        return item.code == key;
      });
      if (itemdatalist.length == 0) {
        itemdatalist = inpLikedate.filter((item) => {
          return item.code == key;
        });
      }
      console.log("itemdatalist", itemdatalist);
      let itemdata = {};
      if (itemdatalist.length > 0) {
        itemdata = itemdatalist[0];
        if (itemdata.required && !itemdata.del) {
          isEmpty += 1;
        }
      }
      // console.log("isEmpty key",key);
      // console.log("isEmpty required",itemdata);
      // console.log("itemdata",itemdata);
    }
  }

  // alert(isEmpty);

  localStorage.setItem('isEmpty', isEmpty);
  localStorage.setItem('member_id', data["id"]);
  localStorage.setItem('member_tel', data["tel"]);
  localStorage.setItem('member_photo', data["photo"]);
  localStorage.setItem('member_sex', data["sex"]);
  localStorage.setItem('member_birthday', data["birthday"]);
  localStorage.setItem('member_country', data["country"]);
  localStorage.setItem('member_PC', data["PC"]);
  localStorage.setItem('member_idtype', data["idtype"]);
  localStorage.setItem('member_idnumber', data["idnumber"]);
  localStorage.setItem('member_industry', data["industry"]);
  localStorage.setItem('member_company', data["company"]);
  localStorage.setItem('member_duties', data["duties"]);
  localStorage.setItem('member_as', data["as"]);
  localStorage.setItem('member_years', data["years"]);
  localStorage.setItem('member_like0', data["like0"]);
  localStorage.setItem('member_like1', data["like1"]);
  localStorage.setItem('member_like2', data["like2"]);
  localStorage.setItem('member_like3', data["like3"]);
  localStorage.setItem('member_like4', data["like4"]);
  localStorage.setItem('member_like5', data["like5"]);
  localStorage.setItem('member_like6', data["like6"]);

  localStorage.setItem('member_other0', data["other0"]);
  localStorage.setItem('member_other1', data["other1"]);
  localStorage.setItem('member_other2', data["other2"]);
  localStorage.setItem('member_other3', data["other3"]);
  localStorage.setItem('member_other6', data["other6"]);

  localStorage.setItem('member_nickName', data["nickName"]);
  localStorage.setItem('member_avatarUrl', data["avatarUrl"]);
  localStorage.setItem('member_email', data["email"]);
  localStorage.setItem('member_level', data["level"]);
  localStorage.setItem('member_name', data["name"]);

  localStorage.setItem('member_openid', data["openid"]);

  localStorage.setItem('member_auth', data["auth"]);

  localStorage.setItem('member_jf', data["jf"]);
}
function clearMemberInfo() {
  localStorage.setItem('isEmpty', '');
  localStorage.setItem('member_id', '');
  localStorage.setItem('member_tel', '');
  localStorage.setItem('member_photo', '');
  localStorage.setItem('member_sex', '');
  localStorage.setItem('member_birthday', '');
  localStorage.setItem('member_country', '');
  localStorage.setItem('member_PC', '');
  localStorage.setItem('member_idtype', '');
  localStorage.setItem('member_idnumber', '');
  localStorage.setItem('member_industry', '');
  localStorage.setItem('member_company', '');
  localStorage.setItem('member_duties', '');
  localStorage.setItem('member_as', '');
  localStorage.setItem('member_years', '');
  localStorage.setItem('member_like0', '');
  localStorage.setItem('member_like1', '');
  localStorage.setItem('member_like2', '');
  localStorage.setItem('member_like3', '');
  localStorage.setItem('member_like4', '');
  localStorage.setItem('member_like5', '');
  localStorage.setItem('member_like6', '');

  localStorage.setItem('member_other0', '');
  localStorage.setItem('member_other1', '');
  localStorage.setItem('member_other2', '');
  localStorage.setItem('member_other3', '');
  localStorage.setItem('member_other6', '');

  localStorage.setItem('member_nickName', '');
  localStorage.setItem('member_avatarUrl', '');
  localStorage.setItem('member_email', '');
  localStorage.setItem('member_level', '');
  localStorage.setItem('member_name', '');

  localStorage.setItem('member_auth', '');

  localStorage.setItem('member_openid', '');

  localStorage.setItem('member_jf', '0');
}

function setbaseinfo(data) {
  localStorage.setItem('loginshow', data["loginshow"]);
  localStorage.setItem('yscontent', data["yscontent"]);
  localStorage.setItem('sjcontent', data["sjcontent"]);
}

function getbaseinfo() {
  return {
    loginshow: localStorage.getItem('loginshow') !== null ? localStorage.getItem('loginshow') : 'false',
    yscontent: localStorage.getItem('yscontent') !== null ? localStorage.getItem('yscontent') : '',
    sjcontent: localStorage.getItem('sjcontent') !== null ? localStorage.getItem('sjcontent') : '',
    dev: this.dev,
  }
}

function getMemberinfo() {
  return {
    isEmpty: localStorage.getItem('isEmpty') !== null ? localStorage.getItem('isEmpty') : '',
    member_id: localStorage.getItem('member_id') !== null ? localStorage.getItem('member_id') : '',
    member_tel: localStorage.getItem('member_tel') !== null ? localStorage.getItem('member_tel') : '',
    member_photo: localStorage.getItem('member_photo') !== null ? localStorage.getItem('member_photo') : '',
    member_sex: localStorage.getItem('member_sex') !== null ? localStorage.getItem('member_sex') : '',
    member_birthday: localStorage.getItem('member_birthday') !== null ? localStorage.getItem('member_birthday') : '',
    member_country: localStorage.getItem('member_country') !== null ? localStorage.getItem('member_country') : '',
    member_PC: localStorage.getItem('member_PC') !== null ? localStorage.getItem('member_PC') : '',
    member_idtype: localStorage.getItem('member_idtype') !== null ? localStorage.getItem('member_idtype') : '',
    member_idnumber: localStorage.getItem('member_idnumber') !== null ? localStorage.getItem('member_idnumber') : '',
    member_industry: localStorage.getItem('member_industry') !== null ? localStorage.getItem('member_industry') : '',
    member_company: localStorage.getItem('member_company') !== null ? localStorage.getItem('member_company') : '',
    member_duties: localStorage.getItem('member_duties') !== null ? localStorage.getItem('member_duties') : '',
    member_as: localStorage.getItem('member_as') !== null ? localStorage.getItem('member_as') : '',
    member_years: localStorage.getItem('member_years') !== null ? localStorage.getItem('member_years') : '',
    member_like0: localStorage.getItem('member_like0') !== null ? localStorage.getItem('member_like0') : '',
    member_like1: localStorage.getItem('member_like1') !== null ? localStorage.getItem('member_like1') : '',
    member_like2: localStorage.getItem('member_like2') !== null ? localStorage.getItem('member_like2') : '',
    member_like3: localStorage.getItem('member_like3') !== null ? localStorage.getItem('member_like3') : '',
    member_like4: localStorage.getItem('member_like4') !== null ? localStorage.getItem('member_like4') : '',
    member_like5: localStorage.getItem('member_like5') !== null ? localStorage.getItem('member_like5') : '',
    member_like6: localStorage.getItem('member_like6') !== null ? localStorage.getItem('member_like6') : '',

    member_other0: localStorage.getItem('member_other0') !== null ? localStorage.getItem('member_other0') : '',
    member_other1: localStorage.getItem('member_other1') !== null ? localStorage.getItem('member_other1') : '',
    member_other2: localStorage.getItem('member_other2') !== null ? localStorage.getItem('member_other2') : '',
    member_other3: localStorage.getItem('member_other3') !== null ? localStorage.getItem('member_other3') : '',
    member_other6: localStorage.getItem('member_other6') !== null ? localStorage.getItem('member_other6') : '',

    member_nickName: localStorage.getItem('member_nickName') !== null ? localStorage.getItem('member_nickName') : '',
    member_avatarUrl: localStorage.getItem('member_avatarUrl') !== null ? localStorage.getItem('member_avatarUrl') : '',
    member_email: localStorage.getItem('member_email') !== null ? localStorage.getItem('member_email') : '',
    member_level: localStorage.getItem('member_level') !== null ? localStorage.getItem('member_level') : '',
    member_name: localStorage.getItem('member_name') !== null ? localStorage.getItem('member_name') : '',

    member_auth: localStorage.getItem('member_auth') !== null ? localStorage.getItem('member_auth') : '',

    member_openid: localStorage.getItem('member_openid') !== null ? localStorage.getItem('member_openid') : '',

    member_jf: localStorage.getItem('member_jf') !== null ? localStorage.getItem('member_jf') : '0',
  }
}


function setIndexscroll(value) {
  localStorage.setItem('IndexScroll', value);
}
function getIndexscroll() {
  let value = localStorage.getItem('IndexScroll') !== null ? localStorage.getItem('IndexScroll') : '0';
  return value;
}
function setPlistscroll(value) {
  localStorage.setItem('PlistScroll', value);
}
function getPlistscroll() {
  let value = localStorage.getItem('PlistScroll') !== null ? localStorage.getItem('PlistScroll') : '0';
  return value;
}



function isMobile(value) {
  return /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/.test(value);
  return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value);
}
function isEmail(email) {
  var myreg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  if (!myreg.test(email)) {
    return false;
  } else {
    return true;
  }
}

// 暴露出这些属性和方法
export default {
  jfgz,
  baseUrl,
  dev,
  loginB,
  commonFun,
  setLoginInfo,
  clearLoginInfo,
  getLoginA,
  setTdataInfo,
  getTdata,
  clearTdata,
  setFinish,
  setPageKey,
  getPageKey,
  getSider,
  searchrule,
  checkrule,
  setlocalStorage,
  getlocalStorage,
  // gethkindtext,
  setIndexDclass,
  getIndexDclass,
  setIndexscroll,
  getIndexscroll,
  setPlistscroll,
  getPlistscroll,
  setMemberInfo,
  clearMemberInfo,
  getMemberinfo,
  isMobile,
  isEmail,
  inpbasedate,
  inpLikedate,
  inpbmdate,
  getInpBDvalue,
  getInpBMvalue,
  setbaseinfo,
  getbaseinfo,
  setauth,
  setbaseUrl,
  setdev,
  gethwkindname,
  hwkindlist,
  resetMemberinfo,
}