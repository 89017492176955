import request from '../utils/request'
import global from '../common'
import qs from 'qs'

export const checklogin = query => {
    console.log()
    return request({
        url: global.baseUrl + 'checklogin.php',
        method: 'post',
        params: query
    });
};

export const fetchData = query => {
    console.log()
    return request({
        url: global.baseUrl + 'table.php',
        method: 'post',
        params: query
    });
};
export const fetchNowTime = query => {
    return request({
        url: global.baseUrl + 'nowtime.php',
        method: 'post',
        params: query
    });
};
export const fetchKsList = query => {

    return request({
        url: global.baseUrl + 'get_kslist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const login = query => {
    return request({
        url: global.baseUrl + 'login.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchMyuserinfo = query => {
    return request({
        url: global.baseUrl + 'get_myuserinfo.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//试卷接口
export const fetchpaperlist = query => {
    return request({
        url: global.baseUrl + 'get_paperlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updatePaper = query => {
    return request({
        url: global.baseUrl + 'update_paper.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addPaper = query => {
    return request({
        url: global.baseUrl + 'add_paper.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delPaper = query =>{
    return request({
        url: global.baseUrl + 'del_paper.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
//查询题目接口
export const searchTmList = query => {
    return request({
        url: global.baseUrl + 'get_searchtmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};




//题库接口
export const fetchTkKind = query => {
    return request({
        url: global.baseUrl + 'get_tkkind.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addTkKind = query =>{
    return request({
        url: global.baseUrl + 'add_tkkind.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateTkKind = query =>{
    return request({
        url: global.baseUrl + 'update_tkkind.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delTkKind = query =>{
    return request({
        url: global.baseUrl + 'del_tkkind.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

//题目接口
export const fetchTmList = query => {
    return request({
        url: global.baseUrl + 'get_tmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addTmList = query =>{
    return request({
        url: global.baseUrl + 'add_tmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
}


export const delTmList = query =>{
    return request({
        url: global.baseUrl + 'del_tmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateTmList = query =>{
    return request({
        url: global.baseUrl + 'update_tmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

//批量题目操作
export const addTmLists = query =>{
    return request({
        url: global.baseUrl + 'add_tmlists.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

export const getPltmlist = query =>{
    return request({
        url: global.baseUrl + 'get_pltmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delPltmlist = query =>{
    return request({
        url: global.baseUrl + 'del_pltmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
}


//查询日志
export const fetchLog = query => {
    return request({
        url: global.baseUrl + 'get_log.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//分类接口
export const fetchClass = query => {
    return request({
        url: global.baseUrl + 'get_class.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchTreeClass = query => {
    return request({
        url: global.baseUrl + 'get_treeclass.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addClass = query =>{
    return request({
        url: global.baseUrl + 'add_class.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delClass = query =>{
    return request({
        url: global.baseUrl + 'del_class.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateClass = query =>{
    return request({
        url: global.baseUrl + 'update_class.php',
        method: 'post',
        data: qs.stringify(query),
    });
}





//组织结构接口
export const fetchOrg = query => {
    return request({
        url: global.baseUrl + 'get_org.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchTreeOrg = query => {
    return request({
        url: global.baseUrl + 'get_treeorg.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addOrg = query =>{
    return request({
        url: global.baseUrl + 'add_org.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delOrg = query =>{
    return request({
        url: global.baseUrl + 'del_org.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateOrg = query =>{
    return request({
        url: global.baseUrl + 'update_org.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const fetchOrgusers = query =>{
    return request({
        url: global.baseUrl + 'get_orgusers.php',
        method: 'post',
        data: qs.stringify(query),
    });
}



//任务接口
export const fetchTask = query => {
    return request({
        url: global.baseUrl + 'get_task.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchTaskone = query => {
    return request({
        url: global.baseUrl + 'get_taskone.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addTask = query =>{
    return request({
        url: global.baseUrl + 'add_task.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delTask = query =>{
    return request({
        url: global.baseUrl + 'del_task.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateTask = query =>{
    return request({
        url: global.baseUrl + 'update_task.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const checkTask = query => {
    return request({
        url: global.baseUrl + 'check_task.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateTaskUsers = query => {
    return request({
        url: global.baseUrl + 'update_taskusers.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//分享文件接口

export const fetchSharefile = query => {
    return request({
        url: global.baseUrl + 'get_sharefile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addSharefile = query => {
    return request({
        url: global.baseUrl + 'add_sharefile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateSharefileSlevel = query => {
    return request({
        url: global.baseUrl + 'update_SharefileSlevel.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delSharefile = query => {
    return request({
        url: global.baseUrl + 'del_sharefile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//MarkerList
export const fetchMarkerlist = query => {
    return request({
        url: global.baseUrl + 'get_markerlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateMarkerlist = query => {
    return request({
        url: global.baseUrl + 'update_markerlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addMarkerlist = query => {
    return request({
        url: global.baseUrl + 'add_markerlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delMarkerlist = query => {
    return request({
        url: global.baseUrl + 'del_markerlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delMarkerdata = query => {
    return request({
        url: global.baseUrl + 'del_markerdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchSreport = query => {
    return request({
        url: global.baseUrl + 'get_sreport.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


export const fetchMdatas = query => {
    return request({
        url: global.baseUrl + 'get_mdatas.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchMarkerdatalist = query => {
    return request({
        url: global.baseUrl + 'get_markerdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addMarkerdata = query => {
    return request({
        url: global.baseUrl + 'add_markerdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateMarkerdata = query => {
    return request({
        url: global.baseUrl + 'update_markerdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



export const fetchZgdata = query => {
    return request({
        url: global.baseUrl + 'get_zgdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addZgdata = query => {
    return request({
        url: global.baseUrl + 'add_zgdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delZgdata = query => {
    return request({
        url: global.baseUrl + 'del_zgdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



export const fetchDmdata = query => {
    return request({
        url: global.baseUrl + 'get_dmdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addDmdata = query => {
    return request({
        url: global.baseUrl + 'add_dmdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateDmdata = query => {
    return request({
        url: global.baseUrl + 'update_dmdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delDmdata = query => {
    return request({
        url: global.baseUrl + 'del_dmdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};







//公文接口
export const fetchGw = query => {
    return request({
        url: global.baseUrl + 'get_gw.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchGwone = query => {
    return request({
        url: global.baseUrl + 'get_gwone.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addGw = query =>{
    return request({
        url: global.baseUrl + 'add_gw.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delGw = query =>{
    return request({
        url: global.baseUrl + 'del_gw.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateGw = query =>{
    return request({
        url: global.baseUrl + 'update_gw.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const fetchTuids = query =>{
    return request({
        url: global.baseUrl + 'get_tuids.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

export const returnGw = query =>{
    return request({
        url: global.baseUrl + 'return_gw.php',
        method: 'post',
        data: qs.stringify(query),
    });
}



//获取全部单位接口
export const fetchUnitAll = query => {
    return request({
        url: global.baseUrl + 'get_unitall.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//单位接口
export const fetchUnit = query => {
    return request({
        url: global.baseUrl + 'get_unit.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//单位接口(不看权限)
export const fetchUnita = query => {
    return request({
        url: global.baseUrl + 'get_unita.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addUnit = query =>{
    return request({
        url: global.baseUrl + 'add_unit.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delUnit = query =>{
    return request({
        url: global.baseUrl + 'del_unit.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateUnit = query =>{
    return request({
        url: global.baseUrl + 'update_unit.php',
        method: 'post',
        data: qs.stringify(query),
    });
}


//获取分配单位
export const fetchUnitFp = query => {
    return request({
        url: global.baseUrl + 'get_unitfp.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//考试获取机构接口
export const fetchUnitExam = query => {
    return request({
        url: global.baseUrl + 'get_unitExam.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//单位委托接口
export const fetchUnitWt = query => {
    return request({
        url: global.baseUrl + 'get_unitwt.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addUnitWt = query =>{
    return request({
        url: global.baseUrl + 'add_unitwt.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delUnitWt = query =>{
    return request({
        url: global.baseUrl + 'del_unitwt.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateUnitWt = query =>{
    return request({
        url: global.baseUrl + 'update_unitwt.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

//考试接口
export const fetchExamAll = query => {
    return request({
        url: global.baseUrl + 'get_exam_all.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchExam = query => {
    return request({
        url: global.baseUrl + 'get_exam.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addExam = query =>{
    return request({
        url: global.baseUrl + 'add_exam.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const delExam = query =>{
    return request({
        url: global.baseUrl + 'del_exam.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateExam = query =>{
    return request({
        url: global.baseUrl + 'update_exam.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
export const updateBustype = query =>{
    return request({
        url: global.baseUrl + 'update_bustype.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

//获取考场接口
export const fetchExamingList = query => {
    return request({
        url: global.baseUrl + 'get_examinglist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//上传图片

export const uploadImage = query =>{
    return request({
        url: global.baseUrl + 'upload_image.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

//客户端上传图片

export const uploadImageP = query =>{
    return request({
        url: global.baseUrl + 'upload_image_p.php',
        method: 'post',
        data: qs.stringify(query),
    });
}

//人员管理

export const downloadPfile = query => {
    return request({
        url: global.baseUrl + 'download_pfile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const fetchPfile = query => {
    return request({
        url: global.baseUrl + 'get_pfile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchPfile2 = query => {
    return request({
        url: global.baseUrl + 'get_pfile2.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addPfile = query => {
    return request({
        url: global.baseUrl + 'add_pfile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updatePfile = query => {
    return request({
        url: global.baseUrl + 'update_pfile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delPfile = query => {
    return request({
        url: global.baseUrl + 'del_pfile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//机构退回考生
export const returnPfile = query => {
    return request({
        url: global.baseUrl + 'return_pfile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//报名管理
export const fetchSignup = query => {
    return request({
        url: global.baseUrl + 'get_signup.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addSignup = query => {
    return request({
        url: global.baseUrl + 'add_signup.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const passSignup = query => {
    return request({
        url: global.baseUrl + 'pass_signup.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delSignup = query => {
    return request({
        url: global.baseUrl + 'del_signup.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateSignup = query => {
    return request({
        url: global.baseUrl + 'update_signup.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//考评人签到
export const signinku = query => {
    return request({
        url: global.baseUrl + 'add_signinku.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//考生签到
export const signinpf = query => {
    return request({
        url: global.baseUrl + 'add_signinpf.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//更新实操分数
export const updateSC = query => {
    return request({
        url: global.baseUrl + 'update_sc.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//报名管理
export const fetchResult = query => {
    return request({
        url: global.baseUrl + 'get_result.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//获取登录信息
export const fetchMember = query => {
    return request({
        url: global.baseUrl + 'get_memberinfo.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//获取前端信息
export const fetchMembers = query => {
    return request({
        url: global.baseUrl + 'get_members.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//获取登录信息
export const fetchMemberinfo = query => {
    return request({
        url: global.baseUrl + 'get_memberinfodetall.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//更新用户信息
export const updateMember = query => {
    return request({
        url: global.baseUrl + 'update_memberinfo.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//更新用户信息
export const updateMemberlock = query => {
    return request({
        url: global.baseUrl + 'update_memberlock.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//检查用户信息
export const checkMember = query => {
    return request({
        url: global.baseUrl + 'check_memberlogin.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//添加报名表
export const addBm = query => {
    return request({
        url: global.baseUrl + 'add_bm.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//获取报名表
export const fetchBm = query => {
    return request({
        url: global.baseUrl + 'get_bm.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//获取报名列表
export const fetchBmlist = query => {
    return request({
        url: global.baseUrl + 'get_bmlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//member登出
export const memberLogout = query => {
    return request({
        url: global.baseUrl + 'memberLogout.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//VIP管理
export const fetchVipList = query => {
    return request({
        url: global.baseUrl + 'get_viplist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addVip = query => {
    return request({
        url: global.baseUrl + 'add_viplist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addVips = query => {
    return request({
        url: global.baseUrl + 'add_viplists.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateVip = query => {
    return request({
        url: global.baseUrl + 'update_viplist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delVip = query => {
    return request({
        url: global.baseUrl + 'del_viplist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//
export const fetchMywork = query => {
    return request({
        url: global.baseUrl + 'get_mywork.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//新闻
export const fetchNews = query => {
    return request({
        url: global.baseUrl + 'get_newslist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchNewsone = query => {
    return request({
        url: global.baseUrl + 'get_newsone.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addNews = query => {
    return request({
        url: global.baseUrl + 'add_news.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateNews = query => {
    return request({
        url: global.baseUrl + 'update_news.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delNews = query => {
    return request({
        url: global.baseUrl + 'del_news.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//校区
export const fetchSchools = query => {
    return request({
        url: global.baseUrl + 'get_schoolslist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addSchools = query => {
    return request({
        url: global.baseUrl + 'add_schools.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateSchools = query => {
    return request({
        url: global.baseUrl + 'update_schools.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delSchools = query => {
    return request({
        url: global.baseUrl + 'del_schools.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//课程
export const fetchCourse = query => {
    return request({
        url: global.baseUrl + 'get_courselist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addCourse = query => {
    return request({
        url: global.baseUrl + 'add_course.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateCourse = query => {
    return request({
        url: global.baseUrl + 'update_course.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delCourse = query => {
    return request({
        url: global.baseUrl + 'del_course.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//获取基本配置
export const fetchBase = query => {
    return request({
        url: global.baseUrl + 'get_base.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateBase = query => {
    return request({
        url: global.baseUrl + 'update_base.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateEnBase = query => {
    return request({
        url: global.baseUrl + 'update_enbase.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//知识点
export const fetchZsds = query => {
    return request({
        url: global.baseUrl + 'get_zsdslist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addZsds = query => {
    return request({
        url: global.baseUrl + 'add_zsds.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateZsds = query => {
    return request({
        url: global.baseUrl + 'update_zsds.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delZsds = query => {
    return request({
        url: global.baseUrl + 'del_zsds.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//管理员管理
export const fetchUser = query => {
    return request({
        url: global.baseUrl + 'get_user.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addUser = query => {
    return request({
        url: global.baseUrl + 'add_user.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delUser = query => {
    return request({
        url: global.baseUrl + 'del_user.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const reUserCheck = query => {
    return request({
        url: global.baseUrl + 're_usercheck.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


export const updateUser = query => {
    return request({
        url: global.baseUrl + 'update_user.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updatePassword = query => {
    return request({
        url: global.baseUrl + 'update_password.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateMyPassword = query => {
    return request({
        url: global.baseUrl + 'update_mypassword.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//城市管理
export const fetchCity = query => {
    return request({
        url: global.baseUrl + 'get_city.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addCity = query => {
    return request({
        url: global.baseUrl + 'add_city.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delCity = query => {
    return request({
        url: global.baseUrl + 'del_city.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateCity = query => {
    return request({
        url: global.baseUrl + 'update_city.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//获取当前考试与考生信息
export const fetchExaminfo = query => {
    return request({
        url: global.baseUrl + 'get_examinfo.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//获取当前考试详细信息
export const fetchExamshow = query => {
    return request({
        url: global.baseUrl + 'get_examshow.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//获取适用于当前考试的监考人信息
export const fetchUserLevel4 = query => {
    return request({
        url: global.baseUrl + 'get_user_level4.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//分配考生到机构
export const updateDispense = query => {
    return request({
        url: global.baseUrl + 'update_dispense.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//批量获取题目列表
export const fetchReadexcel = query =>{
    return request({
        url: global.baseUrl + 'readexcel.php',
        method: 'post',
        data: qs.stringify(query),
    });
}


//批量获VIP列表
export const fetchReadexcel2 = query =>{
    return request({
        url: global.baseUrl + 'readexcel2.php',
        method: 'post',
        data: qs.stringify(query),
    });
}


//试卷预览接口
export const fetchPreviewTdata = query =>{
    return request({
        url: global.baseUrl + 'preview_tdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
}


export const breakpfile = query => {
    console.log()
    return request({
        url: global.baseUrl + 'update_breakpfile.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//审核接口

export const fetchExamine = query => {
    return request({
        url: global.baseUrl + 'get_examine.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



export const fetchTdata = query => {
    return request({
        url: global.baseUrl + 'tdataf.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



// export const fetchTdata = query => {
//     return request({
//         url: global.baseUrl + 'tdataf.php',
//         method: 'post',
//         params: qs.stringify(query)
//     });
// };



//考试审核接口
export const addExamcache = query =>{
    return request({
        url: global.baseUrl + 'add_exam_cache.php',
        method: 'post',
        data: qs.stringify(query),
    });
}
//获取当前考试详细信息
export const fetchExamineshow = query => {
    return request({
        url: global.baseUrl + 'get_examineshow.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//更新审核状态
export const updateExamine = query => {
    return request({
        url: global.baseUrl + 'update_examine.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//重考
export const delResult = query => {
    return request({
        url: global.baseUrl + 'del_result.php',
        method: 'post',
        data: qs.stringify(query),
    });
};




//test json.php
export const testjson = query => {
    return request({
        url: global.baseUrl + 'json.php',
        method: 'post',
        params: query
    });
};


//获取科室列表
export const fetchKscodes = query => {
    return request({
        url: global.baseUrl + 'get_kscodes.php',
        method: 'post',
        params: query
    });
};


export const fetchAirdata= query => {
    return request({
        url: global.baseUrl + 'get_airdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchWeatherdata= query => {
    return request({
        url: global.baseUrl + 'get_weatherdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addAirdata= query => {
    return request({
        url: global.baseUrl + 'add_airdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



export const fetchRiversdata= query => {
    return request({
        url: global.baseUrl + 'get_riversdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addRiversdata= query => {
    return request({
        url: global.baseUrl + 'add_riversdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateRiversdata= query => {
    return request({
        url: global.baseUrl + 'update_riversdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delRiversdata= query => {
    return request({
        url: global.baseUrl + 'del_riversdata.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



export const fetchSllist = query => {
    return request({
        url: global.baseUrl + 'get_sl.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addSllist = query => {
    return request({
        url: global.baseUrl + 'add_sl.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delSllist = query => {
    return request({
        url: global.baseUrl + 'del_sl.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


export const updatePro = query => {
    return request({
        url: global.baseUrl + 'update_pro.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const getYzm = query => {
    return request({
        url: global.baseUrl + 'SendSms/src/Sample.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//案例
export const fetchCase = query => {
    return request({
        url: global.baseUrl + 'get_caselist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const fetchMyCase = query => {
    return request({
        url: global.baseUrl + 'get_mycaselist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchCaseOne = query => {
    return request({
        url: global.baseUrl + 'get_case.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addCase = query => {
    return request({
        url: global.baseUrl + 'add_case.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateCase = query => {
    return request({
        url: global.baseUrl + 'update_case.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delCase = query => {
    return request({
        url: global.baseUrl + 'del_case.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const checkMsgId = query => {
    return request({
        url: global.baseUrl + 'check_casemsgid.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

//获奖
export const fetchAwards = query => {
    return request({
        url: global.baseUrl + 'get_awardslist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const fetchAwardsOne = query => {
    return request({
        url: global.baseUrl + 'get_awards.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addAwards = query => {
    return request({
        url: global.baseUrl + 'add_awards.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateAwards = query => {
    return request({
        url: global.baseUrl + 'update_awards.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delAwards = query => {
    return request({
        url: global.baseUrl + 'del_awards.php',
        method: 'post',
        data: qs.stringify(query),
    });
};





//合作伙伴
export const fetchFriend = query => {
    return request({
        url: global.baseUrl + 'get_friendlist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addFriend = query => {
    return request({
        url: global.baseUrl + 'add_friend.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateFriend = query => {
    return request({
        url: global.baseUrl + 'update_friend.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delFriend = query => {
    return request({
        url: global.baseUrl + 'del_friend.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//获取验证码

export const fetchMemberyzm = query =>{
    return request({
        url: global.baseUrl + 'get_memberyzm.php',
        method: 'post',
        data: qs.stringify(query),
    });
}


// //轮播图
// export const fetcSwiper = query => {
//     return request({
//         url: global.baseUrl + 'get_Swiperlist.php',
//         method: 'post',
//         data: qs.stringify(query),
//     });
// };
// export const addSwiper = query => {
//     return request({
//         url: global.baseUrl + 'add_swiper.php',
//         method: 'post',
//         data: qs.stringify(query),
//     });
// };
// export const updateSwiper = query => {
//     return request({
//         url: global.baseUrl + 'update_swiper.php',
//         method: 'post',
//         data: qs.stringify(query),
//     });
// };
// export const delSwiper = query => {
//     return request({
//         url: global.baseUrl + 'del_swiper.php',
//         method: 'post',
//         data: qs.stringify(query),
//     });
// };





export const fetchCc = query => {
    return request({
        url: global.baseUrl + 'get_cc.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const addCc = query => {
    return request({
        url: global.baseUrl + 'add_cc.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const updateCc = query => {
    return request({
        url: global.baseUrl + 'update_cc.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
export const delCc = query => {
    return request({
        url: global.baseUrl + 'del_cc.php',
        method: 'post',
        data: qs.stringify(query),
    });
};


//获取积分记录
export const fetchJflist = query => {
    return request({
        url: global.baseUrl + 'get_jflist.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//获取页面连接
export const fetchWxhtml = query => {
    return request({
        url: global.baseUrl + 'get_wxhtml.php',
        method: 'post',
        data: qs.stringify(query),
    });
};
//获取页面连接内容
export const fetchWxarticle = query => {
    return request({
        url: global.baseUrl + 'get_wxarticle.php',
        method: 'post',
        data: qs.stringify(query),
    });
};



//身份证验证

export const idCardAuth = query => {
    return request({
        url: global.baseUrl + 'memberauth.php',
        method: 'post',
        data: qs.stringify(query),
    });
};




export const h5Pay = query => {
    return request({
        url: global.baseUrl + 'wxpay3/H5_pay.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const jsPay = query => {
    return request({
        url: global.baseUrl + 'wxpay3/JS_pay.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const jsSign = query => {
    return request({
        url: global.baseUrl + 'wxpay3/JS_sign.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const overTuikuan = query => {
    return request({
        url: global.baseUrl + 'wxpay3/OVER_tuikuan.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const updateTuikuanStatus = query => {
    return request({
        url: global.baseUrl + 'update_tuikuanstatus.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const cancelTuikuan = query => {
    return request({
        url: global.baseUrl + 'wxpay3/CANCEL_tuikuan.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const fetchSign = query => {
    return request({
        url: global.baseUrl + 'get_sign.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const Tuikuan = query => {
    return request({
        url: global.baseUrl + 'wxpay3/Tuikuan.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const getSharecode = query => {
    return request({
        url: global.baseUrl + 'share/getsharecode.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const setCz = query => {
    return request({
        url: global.baseUrl + 'share/setcz.php',
        method: 'post',
        data: qs.stringify(query),
    });
};

export const checkPay = query => {
    return request({
        url: global.baseUrl + 'check_pay.php',
        method: 'post',
        data: qs.stringify(query),
    });
};