import Vue from 'vue';
import router from './router'
import 'babel-polyfill'
import Antd from 'ant-design-vue';
import App from './App';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';
import './assets/css/main.css';
// import animated from 'animate.css'
import animated from 'animate.css'
import 'wowjs/css/libs/animate.css';
Vue.use(animated)

Vue.config.productionTip = false;

Vue.use(Antd);

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
