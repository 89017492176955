<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <a-config-provider :locale="locale">
      <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive"></router-view>
    </a-config-provider>
    <a-config-provider :locale="locale">
      <keep-alive include="indexm,index,aiindex,map,plist,ai">
        <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </a-config-provider>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import global from "./common";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import $ from "jquery";
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {
    // // 根据不同路由跳转不同页面
    // if (this._isMobile()) {
    //   console.log('手机端')
    //   this.$router.replace('/indexm')
    // } else {
    //   console.log('pc端')
    //   this.$router.replace('/index')
    // }

    let bl = window.innerHeight / window.innerWidth;
    if (bl > 1.2) {
      if (bl > 1.5) {
        $("meta[name=viewport]").attr("content", "width=750,user-scalable=no");
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
      }
    } else {
      $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
    }

    const currentHost = window.location.host;

    console.log("currentHost:", currentHost);

    if(currentHost.indexOf('localhost')>-1){
      global.setbaseUrl(0);
      global.setdev(0);
    }else if(currentHost.indexOf('all-fields.com')>-1){
      global.setbaseUrl(0);
      global.setdev(1);
    }else if(currentHost.indexOf('moheweb.com')>-1){
      global.setbaseUrl(1);
      global.setdev(0);
    }

    console.log("currentHost.indexOf('moheweb.com'):",currentHost.indexOf('moheweb.com'));

    console.log("baseUrl:",global.baseUrl," dev:",global.dev);
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  // components: {
  //   HelloWorld
  // }
};
</script>